import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_recruit.module.scss"

import ProcessWeb from "src/images/recruit-process-web.jpg"
import ProcessMobile from "src/images/recruit-process-mobile.jpg"

const MidCareerPage = () => (
  <Layout>
    <Seo title="中途採用情報" />
    <div className="pageWrapper">
      <div className="pageTtl">
        <h1>中途採用情報</h1>
      </div>

      <h2 className="h2Blue">募集中の職種</h2>
      <p className="productMsg">
        主にハードオフグループのシステムを中心に新規開発・改修をしています。<br />
        事業拡大に伴い、人員強化のため現在以下の職種を募集しております。
      </p>

      <div className={styles.positionWrapper}>
        <a href="/recruit/mid-career/position1/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>サーバーサイドエンジニア</p>
          </div>
          <p className={styles.positionTxt}>
            上場企業100%子会社で自社HPやシステムの開発・構築・運用に携われます。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position2/"className={styles.positionContents}>
          <div className={styles.position}>
            <p>アプリエンジニア（Android／iOS）</p>
          </div>
          <p className={styles.positionTxt}>
            まだ世の中にない新たなプロダクト開発をしたい方を募集します。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position3/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>ディレクター</p>
          </div>
          <p className={styles.positionTxt}>
            大規模システムの企画から設計、開発ディレクションまで幅広い開発に携われます。主体的にアクションをおこせる方を募集します。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position4/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>Windowsアプリケーションエンジニア</p>
          </div>
          <p className={styles.positionTxt}>
            大規模システムの構築から運用、POSシステムサービスの設計等幅広くシステム開発に携われます。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position5/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>フロントエンドエンジニア</p>
          </div>
          <p className={styles.positionTxt}>
            新規・既存サービスのフロントエンド開発を、バックエンド開発者やデザイナーと協力して開発・構築・運用をしたい方を募集します。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position7/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>インフラエンジニア</p>
          </div>
          <p className={styles.positionTxt}>
            上場企業100%子会社で自社システムの開発・構築・運用に携われます。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

        <a href="/recruit/mid-career/position6/" className={styles.positionContents}>
          <div className={styles.position}>
            <p>ヘルプデスク</p>
          </div>
          <p className={styles.positionTxt}>
            自社開発ソフトウェアをご導入いただいているお客様からのお問合せ対応を行う方を募集します。
          </p>
          <div className={styles.positionLink}>
            <span>詳しく見る</span>
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
              <rect fill="none" height="30" width="30"/>
              <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
            </svg>
          </div>
        </a>

      </div>

      <h2 className="h2Blue">採用の流れ</h2>
      <p className="productMsg">
        選考プロセスの基本的な流れについてご説明します。
      </p>
      <img src={ ProcessWeb } alt="採用の流れ" className={styles.processWeb}/>
      <img src={ ProcessMobile } alt="採用の流れ" className={styles.processMobile}/>
      <p className="italic">※上記は変更となる可能性もございます。</p>

      <h2 className="h2Blue">応募方法</h2>
      <p className="productMsg">
        企業説明をご希望の方、エントリーをご希望の方は応募フォームよりエントリーをお願いします。
      </p>

      <div className="btnLarge">
        <a href="/recruit/mid-career/job-application/">中途採用応募フォームはこちら
          <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px">
            <rect fill="none" height="30" width="30"/>
            <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
          </svg>
        </a>
      </div>
    </div>
  </Layout>
)

export default MidCareerPage
